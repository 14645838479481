import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import { BrowserRouter } from 'react-router-dom'
import { Host } from '@the-platform-company/roboten-ui-react'

ReactDOM.render(
  <HelmetProvider>
    <Helmet>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
    </Helmet>
    <Host>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Host>
  </HelmetProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
