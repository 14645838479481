import React, { CSSProperties, useMemo } from 'react'
import { Routes, Route, Outlet, useLocation, Location } from 'react-router-dom'
import { Modal } from '@mui/base'
import { ViewWrapper } from './components/ViewWrapper'
import { DialogProvider } from './dialogs'
import {
  StateProvider,
  createStore,
  asyncFlow,
} from '@the-platform-company/appbuilder-react-state'
import { RobotenDnDProvider } from '@the-platform-company/react-drag-drop'
import { useConnectionSecrets } from '@the-platform-company/appbuilder-react-connector'
const SuccessView = React.lazy(
  () => import(/* webpackChunkName: "views/success" */ './views/Success')
)
const AddIdentityView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/add-identity" */ './views/AddIdentity')
)
const CallbackView = React.lazy(
  () => import(/* webpackChunkName: "views/callback" */ './views/Callback')
)
const LogoutView = React.lazy(
  () => import(/* webpackChunkName: "views/logout" */ './views/Logout')
)
const HomeView = React.lazy(
  () => import(/* webpackChunkName: "views/home" */ './views/Home')
)
const SignupView = React.lazy(
  () => import(/* webpackChunkName: "views/signup" */ './views/Signup')
)
const LoginView = React.lazy(
  () => import(/* webpackChunkName: "views/login" */ './views/Login')
)
const PasswordChangeView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/password-change" */ './views/PasswordChange'
    )
)
const LoginFailedView = React.lazy(
  () =>
    import(/* webpackChunkName: "views/login-failed" */ './views/LoginFailed')
)
const SuccessSuccessViewView = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/success-view" */ './views/SuccessSuccessView'
    )
)
const AccountAuthCallback = React.lazy(
  () =>
    import(
      /* webpackChunkName: "views/AccountAuthCallback" */ './components/connectors/AccountAuthCallback'
    )
)
const store = createStore()

const modalStyles: CSSProperties = {
  position: 'fixed',
  zIndex: '1300',
  inset: '0px',
}
const App: React.FC = () => {
  const location = useLocation()

  const state = (location.state as { backgroundLocation?: Location }) ?? {}

  const connections = useConnectionSecrets({ connectionNames: ['account'] })
  const assets = useMemo(
    () => ({
      loginRouterErrorSvg:
        '/assets/login-router-error-svg/LoginRouter-Error.svg',
      louginRouterSuccessSvg:
        '/assets/lougin-router-success-svg/LouginRouter-Success.svg',
      paswordChangeSvg: '/assets/pasword-change-svg/pasword-change.svg',
    }),
    []
  )
  return (
    <>
      <StateProvider
        additionalState={{
          assets,
          connections,
        }}
        store={store}
      >
        <RobotenDnDProvider>
          <DialogProvider>
            <>
              <React.Suspense fallback={null}>
                <Routes location={state?.backgroundLocation ?? location}>
                  <Route
                    path="/"
                    element={
                      <ViewWrapper
                        namespace={'cde3c3c1-0abe-4db8-874a-3b0d42d70079'}
                      >
                        <HomeView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/success"
                    element={
                      <ViewWrapper
                        namespace={'4e531281-75a1-4775-9fbc-8093920396ad'}
                      >
                        <SuccessView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/success/success-view"
                    element={
                      <ViewWrapper
                        namespace={'5796c58a-9516-4630-ab04-7c36e4dcc2bd'}
                      >
                        <SuccessSuccessViewView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/add-identity"
                    element={
                      <ViewWrapper
                        namespace={'af05389c-d307-4e6b-b7c7-11d308ca761b'}
                      >
                        <AddIdentityView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/callback"
                    element={
                      <ViewWrapper
                        namespace={'9f9efc90-5b90-4961-aa24-2f376361c3e8'}
                      >
                        <CallbackView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/logout"
                    element={
                      <ViewWrapper
                        namespace={'c2bc3086-1ab1-4817-8912-a5d0c0446d83'}
                      >
                        <LogoutView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <ViewWrapper
                        namespace={'3cb18196-f6cb-47aa-940c-edb79b84aaff'}
                      >
                        <SignupView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/login"
                    element={
                      <ViewWrapper
                        namespace={'5f35a93f-7634-44e1-8add-3cab97e920b2'}
                      >
                        <LoginView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/password-change/:id"
                    element={
                      <ViewWrapper
                        namespace={'079e3fb8-5981-47d2-90ec-c819e43859e9'}
                      >
                        <PasswordChangeView />
                      </ViewWrapper>
                    }
                  />
                  <Route
                    path="/log"
                    element={
                      <ViewWrapper
                        namespace={'d027070f-8094-4dcc-b405-6cf0214a75ff'}
                      >
                        <LoginFailedView />
                      </ViewWrapper>
                    }
                  />

                  <Route
                    path="/auth/account/callback"
                    element={<AccountAuthCallback />}
                  />
                </Routes>

                {/* Show the modal when a `backgroundLocation` is set */}
                {state?.backgroundLocation && (
                  <Routes>
                    <Route
                      path=""
                      element={
                        <Modal
                          open={!!state?.backgroundLocation}
                          style={modalStyles}
                        >
                          <div>
                            <Outlet />
                          </div>
                        </Modal>
                      }
                    >
                      <Route
                        path="/"
                        element={
                          <ViewWrapper
                            namespace={'cde3c3c1-0abe-4db8-874a-3b0d42d70079'}
                          >
                            <HomeView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/success"
                        element={
                          <ViewWrapper
                            namespace={'4e531281-75a1-4775-9fbc-8093920396ad'}
                          >
                            <SuccessView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/success/success-view"
                        element={
                          <ViewWrapper
                            namespace={'5796c58a-9516-4630-ab04-7c36e4dcc2bd'}
                          >
                            <SuccessSuccessViewView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/add-identity"
                        element={
                          <ViewWrapper
                            namespace={'af05389c-d307-4e6b-b7c7-11d308ca761b'}
                          >
                            <AddIdentityView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/callback"
                        element={
                          <ViewWrapper
                            namespace={'9f9efc90-5b90-4961-aa24-2f376361c3e8'}
                          >
                            <CallbackView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/logout"
                        element={
                          <ViewWrapper
                            namespace={'c2bc3086-1ab1-4817-8912-a5d0c0446d83'}
                          >
                            <LogoutView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/signup"
                        element={
                          <ViewWrapper
                            namespace={'3cb18196-f6cb-47aa-940c-edb79b84aaff'}
                          >
                            <SignupView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/login"
                        element={
                          <ViewWrapper
                            namespace={'5f35a93f-7634-44e1-8add-3cab97e920b2'}
                          >
                            <LoginView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/password-change/:id"
                        element={
                          <ViewWrapper
                            namespace={'079e3fb8-5981-47d2-90ec-c819e43859e9'}
                          >
                            <PasswordChangeView />
                          </ViewWrapper>
                        }
                      />
                      <Route
                        path="/log"
                        element={
                          <ViewWrapper
                            namespace={'d027070f-8094-4dcc-b405-6cf0214a75ff'}
                          >
                            <LoginFailedView />
                          </ViewWrapper>
                        }
                      />
                    </Route>
                  </Routes>
                )}
              </React.Suspense>
            </>
          </DialogProvider>
        </RobotenDnDProvider>
      </StateProvider>
    </>
  )
}
export default App
