import React, { SyntheticEvent, useEffect, useState } from 'react'
import { Paper, ClickAwayListener, useTheme } from '@mui/material'
import { usePopper } from 'react-popper'
import { StateProvider } from '@the-platform-company/appbuilder-react-state'
import { ViewWrapper } from './components/ViewWrapper'

type Placement =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top'

interface IDialogData {
  target?: HTMLElement
  viewParams?: Record<string, unknown>
  component: React.ComponentType
  placement: Placement
  namespace: string
  event?: SyntheticEvent
  openAtCursor?: boolean
}

interface IDialogContext {
  dialogs: {
    [key: string]: IDialogData | null
  }
  setDialog: (id: string, dialog: IDialogData | null) => void
  clear: () => void
}

const DEFAULT_STATE = {
  dialogs: {},
}

export const DialogContext = React.createContext<IDialogContext>({
  ...DEFAULT_STATE,
  setDialog: () => {},
  clear: () => {},
})

interface IUseGetPopperPositionParams {
  popperElement: HTMLElement | null
  cursorPosition: {
    mouseY: number
    mouseX: number
  }
}

type TUseGetPopperPosition = (params: IUseGetPopperPositionParams) => {
  top: number
  left: number
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export const useGetPopperPosition: TUseGetPopperPosition = ({
  popperElement,
  cursorPosition,
}) => {
  let left = cursorPosition.mouseX
  let top = cursorPosition.mouseY

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (
    popperElement?.clientWidth &&
    popperElement.clientWidth + cursorPosition.mouseX > windowDimensions.width
  ) {
    left = cursorPosition.mouseX - popperElement.clientWidth
  }

  if (
    popperElement?.clientHeight &&
    popperElement.clientHeight + cursorPosition.mouseY > windowDimensions.height
  ) {
    top = cursorPosition.mouseY - popperElement.clientHeight
  }

  return {
    top,
    left,
  }
}

interface IUseDialogParams {
  id: string
  namespace: string
  component: React.ComponentType
  placement?: Placement
  openAtCursor?: boolean
}
export const useDialog = ({
  id,
  namespace,
  component,
  placement = 'bottom-end',
  openAtCursor,
}: IUseDialogParams) => {
  const context = React.useContext(DialogContext)
  const open = (
    event: React.SyntheticEvent,
    viewParams?: Record<string, unknown>
  ) =>
    context.setDialog(id, {
      namespace,
      component,
      placement,
      target: event.target as HTMLElement,
      viewParams,
      event,
      openAtCursor,
    })
  const clear = () => context.clear()

  return {
    open,
    clear,
  }
}

const Dialog: React.FC<{
  namespace: string
  target?: HTMLElement
  placement: Placement
  viewParams?: Record<string, unknown>
  children?: React.ReactNode
  event?: SyntheticEvent
  openAtCursor?: boolean
}> = (props) => {
  const theme = useTheme()
  const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(
    null
  )

  const event = props.event as SyntheticEvent | undefined

  const { left, top } = useGetPopperPosition({
    cursorPosition: {
      mouseX: (event as unknown as MouseEvent).clientX,
      mouseY: (event as unknown as MouseEvent).clientY,
    },
    popperElement,
  })

  const { styles, attributes } = usePopper(props.target, popperElement, {
    placement: props.placement,
  })

  return (
    <div
      ref={setPopperElement}
      style={{
        ...(props.openAtCursor
          ? {
              position: styles.popper.position,
              top,
              left,
            }
          : styles.popper),
        zIndex: theme.zIndex.tooltip,
      }}
      {...attributes.popper}
    >
      <Paper>
        <ViewWrapper namespace={props.namespace}>
          <StateProvider additionalState={{ viewParams: props.viewParams }}>
            {props.children}
          </StateProvider>
        </ViewWrapper>
      </Paper>
    </div>
  )
}

export const DialogProvider: React.FC<{
  children: React.ReactNode
}> = (props) => {
  const [dialogs, setDialogs] = React.useState<IDialogContext['dialogs']>(
    DEFAULT_STATE.dialogs
  )
  return (
    <DialogContext.Provider
      value={{
        dialogs,
        setDialog: (id, dialog) =>
          setDialogs((state) => ({
            ...state,
            [id]: dialog,
          })),
        clear: () => setDialogs({}),
      }}
    >
      {props.children}
      {Object.entries(dialogs).map(
        ([key, dialog]) =>
          dialog && (
            <Dialog
              key={key}
              target={dialog.target}
              placement={dialog.placement}
              viewParams={dialog.viewParams}
              namespace={dialog.namespace}
              event={dialog.event}
              openAtCursor={dialog.openAtCursor}
            >
              <ClickAwayListener
                onClickAway={() =>
                  setDialogs((state) => ({
                    ...state,
                    [key]: null,
                  }))
                }
              >
                <div>
                  <dialog.component />
                </div>
              </ClickAwayListener>
            </Dialog>
          )
      )}
    </DialogContext.Provider>
  )
}
