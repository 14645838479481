import {
  StateProvider,
  useBrowserState,
} from '@the-platform-company/appbuilder-react-state'
import React from 'react'

export const ViewWrapper: React.FC<{
  children: React.ReactNode
  namespace: string
}> = ({ children, namespace }) => {
  const browserState = useBrowserState()
  return (
    <StateProvider
      key={namespace}
      namespace={namespace}
      additionalState={browserState}
    >
      {children}
    </StateProvider>
  )
}
